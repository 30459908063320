.onboard_ctr {
    background: $white-txt;
    min-height: 100vh;
    padding-top: 50px;
    padding-bottom: 50px;

}

.logout {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.noti_notEl {
    background: #DAF6FF;
    border-radius: 8px;
    padding: 8px 16px;
    align-items: flex-start;

    img {
        position: relative;
        top: 3px;
    }
}

.btnMinWd {
    max-width: 123px;
}

.min_hform {
    min-height: 250px;
}

.input_itm {
    .pwd_icon {
        right: 5px;
        top: 4px;
        cursor: pointer;
    }

    .passinput {
        padding-right: 27px;
    }
}


.onboard_wrp {
    min-width: 500px;
    max-width: 500px;
    margin: auto;
    box-shadow: 0px 4px 17px 0px #00000014;
    border-radius: 10px;
    padding: 30px 40px;

    h3 {
        margin: 30px auto 45px;
    }

    .inputFill {
        outline: none !important;

        &::placeholder {
            background: #fff;
        }
    }

    @media (max-width: 575px) {
        box-shadow: inherit;
        min-width: 100%;
        max-width: 100%;
        padding: 30px 20px;
    }
}

.inputFL_box {
    border: 1px solid $border;
    border-radius: 8px;
}

label {
    color: $gray-txt;
    font-family: Outfit_reg;
    font-size: 14px;

    sup {
        position: relative;
        top: -2px;
        color: #BB0934;
    }
}

.inputFill {
    font-size: 14px;
    font-family: Outfit_reg;
    color: $black-txt;
    width: 100%;

    &:disabled {
        color: #000;
        background: transparent;
        opacity: 1;
    }
}

.btn_cus {
    background: #006A8E;
    color: $white-txt;
    width: 100%;
    font-size: 14px;
    padding: 20px;
    box-shadow: 0px 8px 24px 0px #959DA533;
    border-radius: 10px;
    border: 0;
    outline: none;
    font-family: DMSans_bold;
    display: block;
    text-align: center;
    text-decoration: none !important;

    &.btn_cus__nill--bg {
        border: 1px solid #006A8E;
        color: #006A8E;
    }

    @media (max-width: 480px) {
        padding: 20px 10px;
    }

    &.br_white_btn {
        background: #FAFAFA;
        color: #5D5D5D;
        outline: 1px solid #E6E6E6;
    }

}

.input_grp {
    border: 1px solid $border;
    border-radius: 8px;
    padding: 8px 16px;
    transition: ease-in-out 0.5s;
    word-wrap: break-word;

    &:hover {
        border: 1px solid #006A8E;
    }
}

button.logout_btn {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    border: 0;
    padding: 20px;
    background: #000000;
    color: #fff;
    display: flex;
    align-items: center;
    line-height: 0;
    bottom: 0;
}

//tab map
.tabMap {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;

    input:checked+label {
        background-color: #006A8E !important;

        img {
            filter: brightness(3);
        }
    }
}

.tabMap-inG {
    label {
        border-radius: 10px 0px 0px 10px;
        min-width: 50px;
        padding: 0.5rem;
        background: #fff;
        display: flex;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 50px;

        @media (max-width: 575px) {
            min-width: 40px;
            min-height: 40px;
        }
    }

    &:last-child {
        label {
            border-radius: 10px 10px 10px 10px;
            min-width: 50px;
            height: 100%;
            padding: 0.5rem;
            background: #fff;
            display: flex;

            @media (max-width: 575px) {
                min-width: 40px;
                min-height: 40px;
            }
        }
    }

    img {
        width: 24px;
        height: 24px;
    }
}

.header {
    .logout-dropdown {
        button::after {
            display: none;
        }

        .dropdown-item {
            border-bottom: 1px solid #EFEFEF;

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .search_icon {
        min-width: 320px;

        @media (max-width: 1350px) {
            min-width: 250px;
        }
    }

}

.search_icon {
    background-image: url("../../../public/img/searchIcon.svg");
    background-position: center left 8px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    padding-left: 34px !important;
    max-width: 270px;
    background-color: #F3F3F3 !important;
    border: 1px solid #F3F3F3 !important;
}

.select_icon {
    background-image: url("../../../public/img/select_icon.svg");
    background-position: center right 8px;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    padding-right: 34px !important;
    max-width: 270px;
}

.form_output {
    .input_grp {
        background: #F6F7FB;
        border: none;
    }

    label {
        color: #5E6366;
    }

    .out_txt {
        color: #ABAFB1;
        font-size: 14px;
        font-family: Outfit_reg;
    }
}

.modal_header {
    font-size: 20px;
    font-family: DMSans_bold;
    color: $black-txt;
    line-height: 0;
    margin-top: 10px;
}

.ant-drawer-content-wrapper {
    width: 450px !important;

    @media (max-width: 575px) {
        width: 100% !important;
        max-width: 100% !important;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

input,
select {
    outline: none;
}

.input_grp {
    button#dropdown-basic {
        background: transparent !important;
        padding: 0 !important;
        font-size: 14px !important;
        border: none !important;
        width: 100%;
        text-align: left;
    }

    .p-multiselect.p-multiselect-chip .p-multiselect-token {
        background: #F5F5F5;
        padding: 5px 10px;
        margin: 5px;
        margin-left: 0;
        margin-right: 10px;

        &:first-child {
            margin-left: 0;
        }

        .p-multiselect-token-label {
            font-size: 12px;
            margin-right: 4px;
            font-family: DMSans_bold;
        }
    }


}

.p-multiselect-panel {
    background: #F5F5F5 !important;
    margin-top: 15px;
}

div#language {
    outline: none !important;
    box-shadow: none !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #000 !important;
    background: #000 !important;
    border-radius: 5px !important;
    width: 18px;
    height: 18px;
}

.p-multiselect .p-multiselect-trigger {
    width: 1rem;
}

.p-checkbox-box {
    width: 18px;
    height: 18px;
    position: relative;
    top: 3px;
}

.p-multiselect-header {
    display: none;
}

.p-checkbox.p-component {
    margin-right: 5px;
}

li.p-multiselect-item {
    padding: 10px 0 !important;
    border-bottom: 1px solid #E5E3E4 !important;
    background: transparent !important;
    font-size: 14px;
    color: #000;

    &:last-child {
        border-bottom: none !important;
    }
}

ul.p-multiselect-items.p-component {
    padding-left: 1rem !important;
}

.form-check-input:checked {
    background-color: #000 !important;
    border-color: #000 !important;
}

.form-check-label {
    color: #000;
}

.form-check-input {
    border: 1px solid #000 !important;
}

.input_grp {
    .form-check {
        cursor: pointer;
    }

    .dropdown-menu.show {
        width: 100%;
    }

    .dropdown-item {
        background-color: transparent !important;
    }

    .dropdown-toggle {

        &::before {
            position: absolute;
            content: "";
            background-image: url("../../../public/img/dropdown_arrow.svg");
            width: 1rem;
            height: 1rem;
            right: 0;
            background-repeat: no-repeat;
            opacity: 0.6;
            background-size: 100% 100%;
        }

        &::after {
            display: none;
        }
    }


}

.gm-ui-hover-effect {
    top: 3px !important;
    right: 3px !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.footer_modal {
    .btn_cus {
        padding: 12px;
    }
}

.gm-ui-hover-effect>span {
    width: 21px !important;
    height: 21px !important;
    margin: 5px !important;
}

.p-checkbox .p-checkbox-box {
    border: 1px solid #000 !important;
    border-radius: 4px !important;
}

input[type=checkbox] {
    accent-color: #006A8E;
}

.boldtxt_on {
    color: #000000;
    font-family: DMSans_bold;
    font-size: 20px;

    @media (max-width: 575px) {
        font-size: 18px;
    }
}

.ortxtAfBE {
    position: relative;

    &::after,
    &::before {
        position: absolute;
        content: "";
        background: #EEEEEE;
        height: 1px;
        width: 40%;
        top: 50%;
        transform: translateY(-50%);
    }

    &::after {
        right: 0;
    }

    &::before {
        left: 0;
    }
}

// multip select box 
.onboard_ctr {


    .css-13cymwt-control {
        border: none !important;
    }

    .css-t3ipsp-control {
        border-color: transparent !important;
        box-shadow: none !important;

    }

    .css-1fdsijx-ValueContainer {
        padding-left: 0 !important;
    }

    .css-t3ipsp-control:hover {
        border-color: transparent !important;
        box-shadow: none !important;
    }

    .css-1jqq78o-placeholder {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .css-qbdosj-Input {
        margin: 0 !important;
    }

    .css-1hb7zxy-IndicatorsContainer {
        display: none;
    }

    // .css-13cymwt-control,
    // .css-t3ipsp-control {
    //     min-height: initial;
    //     border: none !important;
    //     background: url("../../../public/img/dropdown_arrow.svg") no-repeat center right;
    // }

}

.gm-style-iw-chr {
    display: none;
}

.eclip1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}