.boxshd {
    box-shadow: 0px 4px 17px 0px #00000014;
}

.border--gray {
    border: 1px solid #EFEFEF;
}

.modal__txt--bg {
    background-color: #FAFAFA;
}

.bg__light__gray {
    background: #FAFAFA;
}

.border--gray--secondry {
    border: 1px solid #CFD3D4;
}

.output__data--txt {
    font-size: 14px;
    font-family: Outfit_reg;
    color: #000;
}

.max--w--184 {
    max-width: 184px;
}

.zipcode-text {
    background: #006A8E;
    font-size: 12px;
    color: #fff;
    padding: 5px 10px;
    border-radius: 50px;
}

.active-text-btn-green {
    font-size: 10px;
    color: #fff;
    background: #009718;
    padding: 6px 20px;
    border-radius: 5px;
    font-family: DMSans_med;
}

.notification__top {
    background: #DAF6FF;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #E1E1E1;
}

.border--rad10 {
    border-radius: 10px !important;
}

.heading {
    font-size: 18px !important;
    font-family: DMSans_bold;
}

.wfc {
    width: fit-content;
}

.pointer {
    cursor: pointer;
}

hr {
    color: #E9E9E9;
}

.fz12 {
    font-size: 12px !important;
    font-family: DMSans_med;
}

.py-12p {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.max-wf {
    // max-width: fit-content;
    width: 16px !important;
    height: 16px !important;
    max-width: 16px !important;
    min-width: 16px;
}

.fz16 {
    font-size: 16px;
}

.fz14 {
    font-size: 14px !important;
}

.fz18 {
    font-size: 18px;
}

.mt30 {
    margin-top: 30px;
}

.mb30 {
    margin-bottom: 30px;
}

.header {
    padding: 23px 36px;

    @media (max-width: 1350px) {
        padding: 23px 20px;
    }
}

.map {
    #map {
        height: calc(100vh - 99px) !important;
        object-fit: cover;
    }
}

.clearFilter {
    font-size: 16px;
    color: #0269E3;
    font-family: DMSans_med;
    cursor: pointer;
    white-space: nowrap;
}

.profile_iconV {
    max-width: 130px;
    min-width: 130px;
    height: 130px;
    border-radius: 50%;
}

.pointer {
    cursor: pointer;
}

.form-control {
    min-height: 50px;
    font-size: 16px;
}

.max200 {
    max-width: 200px;
}

.mobile_fjbt {
    @media (max-width: 767px) {
        justify-content: space-between;
        width: 100%;
    }
}

.dash_content {
    padding: 40px;
    padding-bottom: 30px;
    position: relative;
    max-height: calc(100vh - 87px);
    overflow: auto;

    @media (max-width: 1350px) {
        padding: 30px 20px;

    }

    @media (max-width: 1199px) {
        max-height: 100vh;
    }
}

.page {
    color: #8B909A;
    font-size: 16px;
    font-family: DMSans_med;

    @media (max-width: 480px) {
        font-size: 14px;
    }

    select {
        border: 1px solid #E9E7FD;
        background: #fff;
        padding: 5px 10px;
        font-size: 14px;
        border-radius: 5px;
    }
}




.dropdownheader {
    .dropdown-item {
        font-size: 1.4rem;
    }

    .dropdown-toggle {
        background: white !important;
        // border: none !important;
        color: #464F60 !important;
        font-size: 1.4rem !important;
        font-family: "DMSansR";
        font-size: 16px;
        padding: 12px 15px;
        background-color: #fff;
        border: 1px solid #D8D8D8 !important;
        border-radius: 4px;
        width: 100%;
        color: #000;
        min-width: 142px;
        min-height: 50px;
        margin-top: 5px;
        transition: ease-in-out 0.3s;
        outline: none !important;
        // min-height: 50px;
        font-family: "DMSansR";
        text-align: left;

        &:focus {
            border: 1px solid #000000;
        }

        &::after {
            right: 8px;
            position: absolute;
            top: 23px;
            border-top: 0.5em solid #A1A9B8;
            border-right: 0.5em solid transparent;
            border-left: 0.5em solid transparent;
        }
    }

    .dropdown-itm {
        // font-size: 1.4rem;
        // margin: 5px 5px;
        cursor: pointer;
        color: #000;
        padding: 20px;
        border-bottom: 1px solid #CACACA;
        // font-family: "DMSansR";

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    .dropdown-menu.show {
        min-width: 14.5rem;
        border-radius: 10px;
        margin-top: 8px;
        min-width: 200px;
        max-width: 200px;
        max-height: 250px;
        // min-height: 250px;
        overflow: auto;

        .btnC {
            // min-height: 40px;
        }
    }
}

.header {
    .dropdownheader {
        .dropdown-toggle {
            font-size: 16px !important;
            width: 200px !important;
            box-shadow: none !important;
            font-family: DMSans_med;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 30px;
        }

        .dropdown-item {
            font-size: 14px;
            font-family: DMSans_med;
        }


    }

    .filterHiring {
        .dropdownheader .dropdown-toggle.bg-litBlue {
            background-color: #DAF6FF !important;
        }

        .dropdown-menu.show {
            min-width: 300px;
            max-width: 300px;
        }

        .dropdown-item {
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
        }
    }
}

.error {
    color: "red"
}

.custom-multiselect .p-multiselect-token {
    background-color: #f0f0f0;
    /* Change background color */
    // border: 1px solid #ccc;
    /* Change border color */
    font-family: Outfit_reg;
    border-radius: 20px;
    /* Adjust border radius */
    padding: 2px 6px;
    color: #000;
    /* Adjust padding */
}

.custom-multiselect .p-multiselect-token .p-multiselect-token-icon {
    // color: black;
    // background-color: #000;
    border-radius: 100px;
    height: 15px;

    /* Change icon color */
}

.tooltip-inner {
    background: #fff !important;
    border: 1px solid #F0EFEF;
    max-width: 300px !important;
    padding: 10px !important;
    box-shadow: 0px 4px 16px 0px #0000000F;
    text-align: left !important;
    font-family: Outfit_reg;
    opacity: 1 !important;

}

.tooltip.show {
    opacity: 1 !important;
}

.actionTooltip {
    color: #009718;
    background: #E3F4E6;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 10px;
}

.center_line--dash {
    border-style: dashed;
    border-width: 1px;
    border-color: #CECECE;
}

.css-13cymwt-control {
    min-height: initial !important;
    outline: none !important;
}

.css-t3ipsp-control {
    min-height: initial !important;
    outline: none !important;
    border: none !important;
}

.no-need-mobile {
    @media (max-width: 767px) {
        display: none !important;
    }
}

.mobile__checkbox {
    @media (min-width: 767px) {
        display: none;
    }

    position: absolute;
    right: 10px;
    top: 10px;

    input {
        width: 20px;
        height: 20px;
    }
}

.p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none !important;
}