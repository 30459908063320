$gray-txt: #5E6366;
$white-txt: #FFFFFF;
$border: #CFD3D4;
$red-txt: #FF2A2A;


$bg__light__grey: #F6F6F6;

.bg__light__grey {
    background: $bg__light__grey !important;
}

.text__light--gray {
    color: $bg__light__grey !important;
}

$black-txt : #000000;

.black {
    color: $black-txt;
}

.redtxt {
    color: $red-txt;
}

.graytxt {
    color: $gray-txt !important;
}

.bg-litBlue {
    background-color: #DAF6FF !important;
}

.drBlue {
    background: #DAF6FF;
}

.lgBlue {
    background: #EAFAFF;
}

.graycr {
    background-color: #FAFAFA;
}

.bluedrk {
    color: #006A8E;
}