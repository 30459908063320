// choose plan
.chooseplan {
    .plan__txt {
        font-size: 18px;
        color: #69717A;
        font-family: DMSans_bold;
    }
}

.chooseplan__footer__price {
    font-size: 24px;
    font-family: DMSans_bold;

    span {
        font-size: 14px;
    }
}

.btn--OliveDrab {
    background: #b3d2dd;
    border: none;
    color: #000;
    padding: 6px 10px;
    border-radius: 3px;
    font-size: 14px;
}


.chooseplan--details {
    .details--list {
        font-size: 14px;
        margin-bottom: 10px;
        align-items: self-start;
    }

    .checkIcon {
        min-width: 16px;
        margin-right: 5px;
        position: relative;
        top: 3px;

        @media (max-width: 575px) {
            min-width: 12px;
            top: 4px;
        }
    }
}

.chooseplan--toggle {
    background-color: #E5E5E5;
    padding: 4px;
    border-radius: 5px;

    .toggle__text {
        color: #1B1C1D;
        background: #E5E5E5;
        padding: 6px 10px;
        font-size: 12px;
        min-width: 60px;
        transition: ease-in-out 0.5s;

        &.active {
            background: #006A8E;
            color: #fff;
            border-radius: 4px;
        }
    }
}

// input third party
.InputElement {
    font-size: 14px !important;
}

.acc__acs {
    .input_itm {
        padding: 4px 0;
    }

    .css-1nmdiq5-menu {
        width: calc(100% + 32px);
        left: -16px;
    }

    @media (max-width: 767px) {
        .css-d7l1ni-option {
            background-color: transparent;
        }
    }

}