.webconatainer {
    // max-width: 1440px;
    margin: auto;
    // padding: 0 140px;
    padding: 0 15px;
}

.webProfile {
    max-width: 576px;
    margin: auto;

}

input[type=text] {
    font-size: 14px;
}

.webtxt {
    font-size: 18px;
    line-height: 31px;
    color: #000;
    word-wrap: break-word;
}

.mainheading {
    font-size: 30px;
    color: #000;
    font-family: DMSans_bold;
}

.jobphoto {
    padding: 40px 0;

    .item {
        margin: 0 12px;

        img {
            aspect-ratio: 1;
            object-fit: cover;
            object-position: center;
            max-height: 266px;
        }
    }
}

.backScreen {
    width: 20px;
}

.services-web {
    padding: 40px 0;
    background: url("../../../public/img/services-bg.png") no-repeat center;
    background-size: cover;
}

.services-grp {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    display: flex;
    margin: 0 -10px;
    // justify-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.services-item {
    background: #1F1F1F;
    color: #fff;
    padding: 17px 10px;
    text-align: center;
    font-size: 14px;
    font-family: DMSans_med;
    margin: 10px;
    width: calc(50% - 20px);
}

.contentWeb {
    padding: 40px 0;

    .webconatainer {
        background: url("../../../public/img/contentWeb-bg.png") no-repeat bottom right;
        background-size: 166px;
    }

}

.webprofile-cont {
    img {
        // max-width: 450px;
        max-width: 100%;
        min-height: 250px;
        aspect-ratio: 1;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }


    .webprofile-on {
        max-width: 450px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        background: url("../../../public/img/profileDownshdw.png") no-repeat bottom center;
        background-size: 100% 100%;
        padding: 20px;
    }

    .webprofile-name {
        font-size: 18px;
        color: #EEEEEE;
        font-family: DMSans_bold;
    }

    .webprofile-job {
        color: #fff;
        background: rgba(255, 255, 255, 0.12);
        width: fit-content;
        padding: 5px 20px;
        border-radius: 30px;
        font-size: 12px;
        margin-top: 5px;
        font-family: Outfit_reg;
    }
}

.labletxt {
    font-size: 14px;
    font-family: DMSans_med;
    color: #000;
}

.gap-dots-border {
    border-style: dotted solid dashed;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-width: thin;
    border-color: #DFDFDF;
}

.wldata {
    font-size: 18px;
    font-family: DMSans_med;
    word-wrap: break-word;
    color: #000;
}

.webProfile {
    header {
        text-align: center;
        padding: 20px 0;
        background: #000;

        .logowb {
            max-width: 100px;
            max-height: 70px;
        }
    }
}

.contactus {
    background: url("../../../public/img/contactus_bg.png") no-repeat center;
    background-size: cover;
    padding: 40px 0;
}

.head2 {
    font-size: 22px;
    font-family: DMSans_bold;
}

.whiteboxtxt {
    background: #FFFFFF;
    padding: 20px 15px;
    // min-width: 440px;
    // max-width: 440px;
    width: 100%;
    margin: auto;

}

// @media (max-width: 1350px) {
//     .webconatainer {
//         padding: 0 100px;
//     }
// }

// @media (max-width: 1199px) {
//     .webconatainer {
//         padding: 0 50px;
//     }

//     .head2 {
//         font-size: 24px;
//     }

//     .contentWeb,
//     .contactus,
//     .jobphoto,
//     .services-web {
//         padding: 50px 0;
//     }
// }

// @media (max-width: 767px) {
//     .webconatainer {
//         padding: 0 25px;
//     }

//     .head2 {
//         font-size: 20px;
//     }

//     .services-item {
//         // grid-template-columns: repeat(2, 1fr);
//         width: calc(50% - 20px);
//     }
// }

// @media (max-width: 575px) {
//     .webconatainer {
//         padding: 0 15px;
//     }

//     .services-item {
//         padding: 7px 6px;
//         font-size: 14px;
//     }

//     .whiteboxtxt {
//         padding: 30px 20px;

//         .wldata {
//             font-size: 20px;
//         }
//     }

//     .whiteboxtxt {
//         width: 100%;
//         min-width: 100%;
//         max-width: 100%;
//     }
// }

// @media (max-width: 1600px) {
//     .services-item {
//         font-size: 14px;
//     }
// }