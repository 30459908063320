.tbwrp {
    width: 100%;
    overflow: auto;
    max-height: 64vh;
    min-height: 25vh;
    position: relative;

    &.ios {
        height: 50vh;
    }
}

.minW1470 {
    @media (min-width: 768px) {
        min-width: 1470px;
    }

}

table {
    width: 100%;
    border-collapse: collapse;

    .action {
        width: 130px;
        min-width: 130px;
    }

    thead {
        th {
            vertical-align: middle;
            font-size: 12px;
            padding: 8px 10px;
            background: #F6F6F6;
            font-family: DMSans_med;
            white-space: nowrap;
            color: $gray-txt;
            position: sticky;
            text-transform: capitalize;
            top: 0;
            z-index: 1;

            &:first-child {
                padding-left: 20px;

            }

            &:last-child {
                padding-right: 20px;

            }


        }
    }

    tbody {
        tr:nth-child(even) {
            background-color: #F6F6F6;
        }

        td {
            font-size: 12px;
            padding: 13px 10px;
            // border-bottom: 1px solid #E1E1E1;
            vertical-align: middle;
            font-family: DMSans_med;
            word-break: break-word;
            white-space: normal;
            color: $black-txt;
            text-transform: capitalize;

            @media (max-width: 575px) {
                padding: 8px 10px;
            }

            &:first-child {
                padding-left: 20px;

            }

            &:last-child {
                padding-right: 20px;

            }
        }


    }

    .tbl-checkbox {
        width: 1.6rem;
        height: 1.6rem;
        position: relative;
        top: 3px;
    }


}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination .ant-pagination-item-active {
    border-color: #006A8E !important;
    background-color: #006A8E !important;
    color: #fff !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination .ant-pagination-item {
    background-color: #F1F2F6;
    color: #8B909A;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
    border-color: #006A8E !important;
    background-color: #006A8E !important;
    color: #fff !important;
}

.paggtbl {
    // margin-top: 20px;

    .ant-pagination {
        margin-left: auto;
        width: fit-content;

        .ant-pagination-options {
            display: none;
        }
    }
}

.td_activebtn {
    background: #009718;
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    max-width: 90px;
    text-align: center;
    display: block;

    @media (max-width: 575px) {
        margin: 5px 0;
    }

}

.td_deactivebtn {
    background: #F65656;
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    max-width: 90px;
    text-align: center;
    display: block;
}

.onboard__table {
    max-height: 300px;
    overflow: auto;
    border: 1px solid #CFD3D4;
    border-radius: 4px;

    table th {
        background-color: #DAF6FF !important;
        color: #000000;
    }

    @media (max-width: 767px) {

        table thead th,
        table thead td {
            display: table-cell;
        }

        table tr {
            display: table-row;
        }

        tbody tr td {
            width: initial;
            display: table-cell;
        }
    }


}

.onboard_ctr {

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px grey;
        border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #006A8E;
        border-radius: 5px;
    }
}